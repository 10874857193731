<template>
	<div>
		<div class="queryBox">
			<div class="mb20">
			<el-cascader  style="width: 200px" v-model="carder_id" class="mr20" :show-all-levels="false" placeholder="搬砖工" size="mini" :options="options"
			 :props="{ checkStrictly: true,expandTrigger:'hover',emitPath: false,value:'id',label:'name' }" clearable @change="agentChang"></el-cascader>
			 
			<el-input size="mini" placeholder="请输入商户订单号" v-model="order_id" clearable class="mr20" style="width: 200px"></el-input>
			
			<el-select v-model="zbOrderType" placeholder="请选择特殊类型" size="mini" class="mr20" clearable>
				<el-option v-for="(item,index) in typeDetail" :key="index" :label="item" :value="index"></el-option>
			</el-select>
			
			<el-select v-model="type" placeholder="请选择订单状态" size="mini" class="mr20" clearable>
				<el-option key="IN" label="入账" value="IN"></el-option>
				<el-option key="OUT" label="出账" value="OUT"></el-option>
			</el-select>
							
			<el-button size="mini" plain class="mr20" @click="setDate(-1)" :disabled="disLoading">前一天</el-button>
			<el-date-picker size="mini" v-model="date" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']" class="mr20"></el-date-picker>
			<el-button size="mini" plain class="mr20" @click="setDate(1)" :disabled="disLoading">后一天</el-button>
				<el-button size="mini" type="primary" :icon="disLoading?'el-icon-loading':'el-icon-search'"  @click="getData" :disabled="disLoading">查询</el-button>
				<el-button size="mini" type="primary"  icon="el-icon-document" @click="getExcel" :disabled="tableData.length == 0">导出Excel</el-button>
			</div>
		</div>
		<template>
			<el-table style=" width:1200px" size="mini" :data="tableData" border="" stripe v-loading="loading" :show-overflow-tooltip="true">
				<el-table-column prop="" label="搬砖工" align="center">
					<template slot-scope="scope">
						{{scope.row.carder_username}} <br/>
						<!-- {{scope.row.business_no}} -->
						
					</template>
				</el-table-column>
				<el-table-column prop="" label="订单号" align="center">
					<template slot-scope="scope">
						{{scope.row.order_id}}
					</template>
				</el-table-column>
				<el-table-column prop="" label="变动前余额" align="center">
					<template slot-scope="scope">
						<span>
							{{scope.row.before_quota}}
						</span>
					</template>
				</el-table-column>
				<el-table-column prop="" label="余额+" align="center">
					<template slot-scope="scope">
						<span v-if="scope.row.change_type=='IN'" class="csuccess">
							+{{scope.row.money}}
						</span>
						<span v-if="scope.row.change_type=='OUT'">
							0
						</span>
					</template>
				</el-table-column>
				<el-table-column prop="" label="余额-" align="center">
					<template slot-scope="scope">
						<span v-if="scope.row.change_type=='OUT'" class="cdanger">
							-{{scope.row.money}}
						</span>
						<span v-if="scope.row.change_type=='IN'">
							0
						</span>
					</template>
				</el-table-column>
				
				<el-table-column prop="" label="变动后余额" align="center">
					<template slot-scope="scope">
						<span>
							{{scope.row.after_quota}}
						</span>
					</template>
				</el-table-column>
				<el-table-column prop="" label="信息" align="center">
					<template slot-scope="scope">
						<span :class="scope.row.notes=='充值'?'csuccess':scope.row.notes=='代付'?'cdanger':scope.row.notes=='解冻'?'cinfo':'cwarning'">							
							{{scope.row.notes}}
						</span>
					</template>
				</el-table-column>
				<el-table-column prop="" label="时间" align="center" width="150">
					<template slot-scope="scope">													
						{{scope.row.add_time}}
					</template>
				</el-table-column>
			</el-table>
		</template>

		<div class="flexX  mt20">
			<el-pagination :hide-on-single-page="true" @size-change="handleSizeChange" @current-change="handleCurrentChange"
			 :current-page="page" :page-sizes="pageSizesArr" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
			 :total="total"></el-pagination>
		</div>


	</div>
</template>

<script>
	import local from '@/util/local'
	export default {
		data() {
			return {
				loading: false,
				disLoading: false,
				tableData: [],	
				date: null,
				pageSizesArr: [20, 50, 100, 200],
				pageSize: 20,
				total: 0,
				page: 1,
				type:'',
				order_id:'',
				business_no:'',
				options: [],
				carder_id: '',
				// 账变筛选项
				typeDetail:[],
				zbOrderType:'',
			};
		},
		created() {
			this.date = [this.$util.timestampToTime(new Date().getTime(), true) + ' 00:00:00', this.$util.timestampToTime(new Date().getTime(), true) + ' 23:59:59']
			this.orderReport()
			this.treeList()
			this.getTypeDetail()

		},
		mounted(){
			let self = this
			document.onkeydown = function(e) {
			  let ev = document.all ? window.event : e
			  if (ev.keyCode === 13) {
				  if(!self.loading){
					  self.getData()
				  }
				
			  }
			}
		},
		destroyed() {
			document.onkeydown = null
		},
		filters: {
			tofixed: function(value) {
				if (value || value == 0) {
					return ((value * 1).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
				}
			}
		},
		methods: {
			getTypeDetail() {
				this.$api.getTypeDetail({}).then(res => {
					if (res.status === 1) {
						this.typeDetail = res.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			agentChang(value) {
				this.carder_id = value
			},
			treeList() {
				this.$api.treeList({}).then(res => {
					if (res.status === 1) {
						this.options = res.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			setDate(day) {
				let data = this.date ? this.date[0] : this.$util.timestampToTime(new Date().getTime(), true)
				let newDate = this.$util.getLastDate(data, day);
				this.date = [newDate + ' 00:00:00', newDate + ' 23:59:59']
				this.getData()
			},
			//搜索按钮
			getData() {
				this.page = 1;
				this.orderReport();
			},
		
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize = val
				this.page = 1
				this.orderReport()
			},
			handleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.page = val
				this.orderReport()
			},
			

			orderReport() {
				let data = {
					carder_id: this.carder_id,
					order_id: this.order_id,
					type: this.type,
					// state: this.state,
					startdate: this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[0]))) : '',
					enddate: this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[1]))) : '',
					page: this.page,
					size: this.pageSize,
					type_detail: this.zbOrderType,
				}
				// this.loading = true
				this.disLoading = true
				this.$api.moneyChangeList(data).then(res => {
					this.disLoading = false
					this.loading = false;
					if (res.status === 1) {
						this.tableData = res.data.data.filter(item=>{
							// if(!item.agent_username){
								return item
							// }
						});
						this.total = res.data.total
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.loading = false
					this.disLoading = false
				})
			},
			//导出Excel
			getExcel() {
				//manager/order/exportList
				console.log(this.$baseURL)
				let username = local.get('username');
				let tid = local.get('tid')
				let carder_id = this.carder_id;
				let order_id = this.order_id;
				let type = this.type;
				let startdate = this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[0]))) : '';
				let enddate = this.date ? this.$util.timestampToTime(Date.parse(new Date(this.date[1]))) : '';
				
				let href =
					`${this.$baseURL}manager/carder/exportMoneyChangeList?username=${username}&tid=${tid}&carder_id=${this.carder_id}&order_id=${this.order_id}&type=${this.type}&startdate=${startdate}&enddate=${enddate}`
				// window.location.href = href
				window.open(href)
			},
			
		}
	};
</script>

<style>
</style>
